// src/components/Login.js

import React, { useState, useEffect, useContext, useRef, useCallback } from "react";

import {
  getAuth, updateProfile, signInWithEmailAndPassword, signOut, reauthenticateWithCredential,
  EmailAuthProvider, updatePassword, createUserWithEmailAndPassword, signInWithCustomToken
} from "firebase/auth";

import { getDoc, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, deleteDoc, getDocs, query, where } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation, useLocation } from 'react-router-dom';
import { FaUser, FaArrowLeft, FaTrash } from 'react-icons/fa';
import { db, app, storage } from "../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ArticlesContext } from '../App';
import { useAdminRole } from "../App";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Logo from '../assets/logo/logo-horizontale-v2.png';

import "easymde/dist/easymde.min.css";
import EasyMDE from 'easymde';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import imageCompression from 'browser-image-compression';

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let userLang;

  useEffect(() => {
    userLang = navigator.language.slice(0, 2) || 'en';

    i18n.changeLanguage(userLang)
  }, []);

  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const auth = getAuth();



  const { setAdminRole, email, setEmail } = useAdminRole();

  const languages = ['en', 'fr', 'es', 'de', 'pt', 'ru', 'it', 'pl', 'cs'];// rajouter les languages des médias


  useEffect(() => { // IF USER IS FROM MOBILE APP
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromUrl = urlParams.get('email');

    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const normalizedEmail = email.toLowerCase();
    try {

      await signInWithEmailAndPassword(auth, normalizedEmail, password);

      const adminDocRef = doc(db, "Admin", "AdminUsers", "AdminRole", normalizedEmail);
      const adminDocSnapshot = await getDoc(adminDocRef);

      if (adminDocSnapshot.exists() && (adminDocSnapshot.data().role === "admin")) { // PREMIÈRE VÉRIFICATION DU ROLE ADMINISTRATEUR À L'ENTRÉE
        setAdminRole(adminDocSnapshot.data().role);

        // Sauvegardez le rôle dans localStorage
        localStorage.setItem('adminRole', adminDocSnapshot.data().role);
        localStorage.setItem('email', normalizedEmail);

        navigate("/AccueilAdmin");
        return;
      }

      if (adminDocSnapshot.exists() && (adminDocSnapshot.data().role === "CM")) { // ROLE ADMINISTRATEUR COMMUNITY MANAGER POUR PHOTO FIGHTER
        setAdminRole(adminDocSnapshot.data().role);

        // Sauvegardez le rôle dans localStorage
        localStorage.setItem('adminRole', adminDocSnapshot.data().role);
        localStorage.setItem('email', normalizedEmail);

        navigate("/AccueilAdmin");
        return;
      }

      let isMedia = false;

      const writerDocRef = doc(db, "Admin", "AdminWriter", "writers", normalizedEmail);
      const writerDocSnapshot = await getDoc(writerDocRef);

      if (writerDocSnapshot?.exists()) { // IF FIGHTAPP WRITER, LOGIN WITH FIGHTAPP CREDENTIALS

        const email = process.env.REACT_APP_FIGHTAPP_FR_EMAIL;
        const password = process.env.REACT_APP_FIGHTAPP_PASSWORD;

        await signInWithEmailAndPassword(auth, email, password);
        isMedia = true;
        localStorage.setItem('email', email); // the email of FightApp where the articles will be publish
        localStorage.setItem('writerEmail', normalizedEmail); // the email of the writer for access to his articles only 

        const writerData = writerDocSnapshot.data();
        // Stocker les données dans localStorage
        localStorage.setItem("writerData", JSON.stringify(writerData));

      } else { // IF OTHER MEDIA

        for (let lang of languages) {
          const mediaDocRef = doc(db, "news", "media", lang, normalizedEmail);
          const mediaDocSnapshot = await getDoc(mediaDocRef);

          if (mediaDocSnapshot.exists() && mediaDocSnapshot.data().role === "media" && mediaDocSnapshot.data().access === true) {
            isMedia = true;
            localStorage.setItem('email', normalizedEmail); // the email of the media where the articles will be publish
            break; // Si on trouve un rôle de media pour une langue, on arrête la boucle
          }
        }
      }

      if (isMedia) {
        navigate("/submitArticle");
      } else {
        // Déconnexion de l'utilisateur s'il n'a pas le bon rôle
        await signOut(auth);
        setError(t("Access denied."));
        alert(t("Access denied."));
      }
    } catch (err) {
      console.error("Erreur lors de la connexion :", err);
      setError(err.message);
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',

    }}>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', borderRadius: '10px', width: '400px', margin: '50px auto',
      }}>


        <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Log in")}</h2>

        {error && <p style={{ color: 'red', marginBottom: '20px' }}>{t('Incorrect email or password.')}</p>}

        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <style>
            {`
            input:focus {
                background-color: white;
                box-shadow: none;  // Pour supprimer le contour bleu
            }
        `}
          </style>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => { setEmail(e.target.value); setError(null) }}
            style={{
              padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0', width: '90%',
              borderRadius: '5px', outline: 'none',
            }}
          />
          <input
            type="password"
            placeholder={t("Password")}
            value={password}
            onChange={(e) => { setPassword(e.target.value); setError(null) }}
            style={{
              padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0', width: '90%',
              borderRadius: '5px', outline: 'none',
            }}
          />
          <button
            type="submit"
            style={{
              padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s'
            }}
          >
            {t("Log in")}
          </button>
        </form>

        <button
          type="button"
          onClick={() => navigate("/submitAccountRequest")}
          style={{
            border: 'none', color: 'black', marginTop: '30px', cursor: 'pointer',
            outline: 'none', background: 'none', fontSize: 14, textDecorationLine: 'underline'
          }}
        >
          {t("Media registration")}
        </button>

      </div>

    </div>
  );
}

export function SubmitAccountRequest() {

  const navigate = useNavigate();

  const { t } = useTranslation();

  let userLang;

  useEffect(() => {
    userLang = navigator.language.slice(0, 2) || 'en';
    console.log('userLang', userLang)
    i18n.changeLanguage(userLang)
  }, []);

  const [mediaName, setMediaName] = useState("");
  const [mediaLogo, setMediaLogo] = useState("");
  const [mediaTwitterAccount, setMediaTwitterAccount] = useState("");
  const [mediaInstagramAccount, setMediaInstagramAccount] = useState("");
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
  const [mediaLanguage, setMediaLanguage] = useState(localStorage.getItem('mediaLanguage') || null);
  const [password, setPassword] = useState("");
  const passwordIsValid = validatePassword(password);
  const [error, setError] = useState(null);
  const auth = getAuth();

  const [errorEmail, setErrorEmail] = useState('');
  const [errorMotdepasse, setErrorMotdepasse] = useState('');
  const [errorUser, setErrorUser] = useState('');

  const { setAdminRole, email, setEmail } = useAdminRole();

  const [showPassword, setShowPassword] = useState(false);

  const languages = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'es', label: 'Español' },
    { value: 'de', label: 'Deutsch' },
    { value: 'pt', label: 'Português' },
    { value: 'ru', label: 'Русский' },
    { value: 'it', label: 'Italiano' },
    { value: 'pl', label: 'Polski' },
    { value: 'cs', label: 'Čeština' }
  ];


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setErrorMotdepasse('The password must contain at least 8 characters, a capital letter and a number.');
      return;
    }

    const usernameTaken = await isUsernameTaken(mediaName, mediaLanguage);
    if (usernameTaken) {
      setErrorUser("This username is already taken.");
      return;
    }
    try {

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;

      //  const userCredential = await authInstance.currentUser.linkWithCredential(credential);
      //  const user = userCredential.user;

      // Set the username in the user's profile
      //  await user.updateProfile({ displayName: username });

      // const AdminMediaRef = doc(db, "Admin", "AdminMedia", language, emailAccount);
      const normalizedEmail = email.toLowerCase();
      const AccountMediaRef = doc(db, "news", "media", mediaLanguage, normalizedEmail);
      await setDoc(AccountMediaRef, {
        instagramAccount: mediaInstagramAccount,
        language: mediaLanguage,
        logo: mediaLogo,
        moderator: false,
        name: mediaName,
        role: 'media',
        twitterAccount: mediaTwitterAccount,
        access: false,
        chatAccess: false,
        followers: 0
      });

      const AccountMediaOnAppRef = doc(db, "users", uid);
      await setDoc(AccountMediaOnAppRef, {
        email: normalizedEmail,
        username: mediaName,
        language: mediaLanguage
      });

      navigate("/login");
      alert(t("Form submitted successfully !"));
    } catch (error) {
      console.log('error', error)
      setErrorEmail('Please use another email address.');
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      flexDirection: 'column'
    }}>
      <div style={{
        top: '0px', height: '50px', width: '100%', display: 'flex',
        justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#ff3030'
      }}>

        <button onClick={() => navigate(-1)} style={{
          background: 'none', border: 'none', color: 'white',
          cursor: 'pointer', fontWeight: 'bolder', justifyContent: 'center', display: 'flex'
        }}>
          <FaArrowLeft size="1.3em" />
        </button>

        <img src={Logo} alt="Logo" style={{ height: '200px', marginLeft: -10 }} />
      </div>

      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
        borderRadius: '10px', width: '100%',
      }}>

        <h2 style={{ color: 'black', marginBottom: '20px', fontSize: 20 }}>{t('Media registration')}</h2>

        {error && <p style={{ color: 'red', marginBottom: '20px' }}>{t("Incorrect email or password.")}</p>}

        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <style>
            {`
            input:focus {
                background-color: white;
                box-shadow: none;  // Pour supprimer le contour bleu
            }
        `}
          </style>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => { setEmail(e.target.value); setErrorEmail(''); }}
            style={{
              padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0', width: '70%',
              borderRadius: '5px', outline: 'none', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}
          />

          {errorEmail !== '' ? (
            <div style={{ width: '70%', alignSelf: 'center', }}>
              <label style={{ color: 'red', fontSize: 14, }}>
                {errorEmail}
              </label>
            </div>
          ) : null}

          <div style={{ width: '70%', margin: '5px 0', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', }}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder={t("Password")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMotdepasse('');
              }}
              style={{
                padding: '10px 5px', border: '1.5px solid #ff3030',
                width: '100%', borderRadius: '5px', outline: 'none', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
              }} />
            <button onClick={() => setShowPassword(!showPassword)} type="button" style={{
              padding: '5px 10px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '5px', marginTop: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s'
            }}>
              {showPassword ? t('Hide') : t('Show')}
            </button>
          </div>
          {errorMotdepasse !== '' ? (
            <div style={{ width: '70%', alignSelf: 'center', }}>
              <label style={{ color: 'red', fontSize: 14, }}>
                {errorMotdepasse}
              </label>
            </div>
          ) : (<label style={{ color: 'white', alignSelf: 'center', fontSize: 14, }}>
            {t("The password must contain at least 8 characters, a capital letter and a number.")}
          </label>)}

          <input
            type="userName"
            placeholder={t("Media name")}
            value={mediaName}
            onChange={(e) => { setMediaName(e.target.value); setErrorUser(''); }}
            style={{
              padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0', width: '70%',
              borderRadius: '5px', outline: 'none', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}
          />

          {errorUser !== '' && (
            <div style={{ width: '90%', alignSelf: 'center', }}>
              <label style={{ color: 'red', fontSize: 14, }}>
                {errorUser}
              </label>
            </div>
          )}

          <div style={{ // Languages
            width: '73%', marginTop: '10px',
          }}>
            <label style={{ fontSize: 15, color: 'grey' }}>{t('Language')} :</label>

            <select
              value={mediaLanguage}
              onChange={(e) => setMediaLanguage(e.target.value)}
              style={{
                marginTop: '5px',
                width: '100%', padding: '10px 25px', border: '1.5px solid #ff3030',
                borderRadius: '5px', outline: 'none', boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              }}
            >
              <option value="">{t("Select a language")}</option>
              {languages.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.label}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            style={{
              padding: '10px 15px', border: 'none', backgroundColor: '#ff3030', margin: '5px 0',
              color: 'white', borderRadius: '5px', marginTop: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s', width: '73%',
            }}
          >
            {t('Send')}
          </button>

        </form>
      </div>
    </div>
  );
}

export default function Account() {
  const [mediaData, setMediaData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [mediaName, setMediaName] = useState("");
  const [mediaLogo, setMediaLogo] = useState("");
  const [mediaSponsors, setMediaSponsors] = useState("");
  const [mediaTwitterAccount, setMediaTwitterAccount] = useState("");
  const [mediaInstagramAccount, setMediaInstagramAccount] = useState("");
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
  const [mediaLanguage, setMediaLanguage] = useState(localStorage.getItem('mediaLanguage') || null);
  const [writerData, setWriterData] = useState(JSON.parse(localStorage.getItem("writerData")) || null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const passwordIsValid = validatePassword(newPassword);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [articles, setArticles] = useState([]);

  const [livechatBanner, setLivechatBanner] = useState([]);

  const { t } = useTranslation();

  let userLang;

  useEffect(() => {
    userLang = navigator.language.slice(0, 2) || 'en';
    i18n.changeLanguage(userLang)
  }, []);

  const navigate = useNavigate();
  const auth = getAuth();


  useEffect(() => { // RÉCUPÉRATION DONNÉES MÉDIA
    if (mediaLanguage && userEmail) {
      console.log('mediaLanguage && userEmail', mediaLanguage, userEmail)
      const mediaDocRef = doc(db, "news", "media", mediaLanguage, userEmail);

      // Utilisez onSnapshot pour écouter les mises à jour en temps réel
      const unsubscribe = onSnapshot(mediaDocRef, (mediaDoc) => {
        if (mediaDoc.exists()) {
          setMediaData(mediaDoc.data());
        }
      });

      const articlesRef = collection(db, "news", "media", mediaLanguage, userEmail, "articles");

      const unsubscribeArticles = onSnapshot(articlesRef, (querySnapshot) => {
        const articlesList = [];
        querySnapshot.forEach((doc) => {
          const articleData = doc.data();
          articlesList.push({ ...articleData, id: doc.id });
        });

        setArticles(articlesList);
      });
      // Assurez-vous de vous désabonner de l'écouteur lorsque le composant est démonté
      return () => {
        unsubscribe();
        unsubscribeArticles();
      }
    }
  }, [auth, db, mediaLanguage, userEmail]);

  const handleLogout = () => { // DÉCONNEXION
    signOut(auth).then(() => {
      navigate("/login");
    }).catch((error) => {
      console.error("Error logging out: ", error);
    });
  };

  useEffect(() => {
    // Quand mediaData change, mettez à jour les valeurs modifiables
    setMediaName(mediaData.name);
    setMediaLogo(mediaData.logo ? mediaData.logo : null);
    setMediaSponsors(mediaData.sponsors ? mediaData.sponsors : null)
    setLivechatBanner(mediaData.livechatBanner ? mediaData.livechatBanner : [])

    if (mediaData.twitterAccount) {
      setMediaTwitterAccount(mediaData.twitterAccount);
    }

    if (mediaData.instagramAccount) {
      setMediaInstagramAccount(mediaData.instagramAccount);
    }

  }, [mediaData]);

  const handleEditClick = () => { // update the media data
    setIsEditing(!isEditing);
  }

  //////////// LOGO
  const [imageFileLogo, setImageFileLogo] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFileLogo(file);
    }
  };
  //////////////


  /////////// SPONSORS

  const [imageFileSponsors, setImageFileSponsors] = useState(null);
  const [noFileSelected, setNoFileSelected] = useState(false);

  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [isCropped, setIsCropped] = useState(false);
  const imageRef = useRef();
  const cropperRef = useRef();



  const [isHovered, setIsHovered] = useState(false); // bouton cofirmer le rognage
  const [isActive, setIsActive] = useState(false); // bouton cofirmer le rognage

  // Gestionnaire pour confirmer le rognage
  const handleCropConfirm = () => {
    cropperRef.current.getCroppedCanvas().toBlob((blob) => {
      setCroppedImageBlob(blob); // Stocker le blob pour la télécharger plus tard
    }, 'image/png');
  };

  const handleImageChangeSponsors = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFileSponsors(file);
      setNoFileSelected(false);

      // Lire l'image et l'afficher pour le recadrage
      const reader = new FileReader();
      reader.onload = (e) => {
        imageRef.current.src = e.target.result;
        if (cropperRef.current) {
          cropperRef.current.destroy(); // Détruire l'instance précédente
        }
        cropperRef.current = new Cropper(imageRef.current, {
          aspectRatio: 600 / 200, // Ratio d'aspect souhaité
          //... autres options
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const fileInputRef = useRef();// sélectionneur d'image
  ///////////

  //////////////// LIVECHAT SPONSORS
  const [newItem, setNewItem] = useState({ name: '', image: null, link: '' });
  const [editingIndex, setEditingIndex] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropperInstancesChatBanner, setCropperInstancesChatBanner] = useState({});
  const imageRefs = useRef({});

  const handleAddItem = async () => {
    if (cropperInstancesChatBanner['new']) {
      const blob = await new Promise(resolve => {
        cropperInstancesChatBanner['new'].getCroppedCanvas().toBlob(resolve, 'image/png');
      });
      const newItemWithBlob = { ...newItem, croppedImageBlob: blob };
      console.log('blob', blob)
      await updateLivechatBannerInFirebase([...livechatBanner, newItemWithBlob]);
    } else {
      // Si pas de recadrage, ajoutez directement
      await updateLivechatBannerInFirebase([...livechatBanner, newItem]);
    }

    // Nettoyage de l'instance de Cropper pour le nouvel item
    if (cropperInstancesChatBanner['new']) {
      cropperInstancesChatBanner['new'].destroy();
      delete cropperInstancesChatBanner['new'];
    }

    setNewItem({ name: '', image: null, link: '' });
    setShowInputs(false);
    setShowCropper(false);
  };

  const handleUpdateItem = async () => {
    if (cropperInstancesChatBanner[editingIndex] && isCropped) {

      const blob = await new Promise(resolve => {
        cropperInstancesChatBanner[editingIndex].getCroppedCanvas().toBlob(resolve, 'image/png');
      });
      const updatedItem = { ...newItem, croppedImageBlob: blob, oldImageUrl: livechatBanner[editingIndex].imageUrl };
      const updatedBanner = [...livechatBanner];
      updatedBanner[editingIndex] = updatedItem;
      await updateLivechatBannerInFirebase(updatedBanner);

      setIsCropped(false);

    } else {

      // Si pas de recadrage, mise à jour directe
      const updatedBanner = [...livechatBanner];
      updatedBanner[editingIndex] = newItem;
      await updateLivechatBannerInFirebase(updatedBanner);

    }

    setNewItem({ name: '', image: null, link: '' });
    setEditingIndex(null);
    setShowInputs(false);
  };


  const handleEditItem = (index) => {
    setEditingIndex(index);
    setNewItem({ ...livechatBanner[index] });
    setShowInputs(true);
    imageRefs.current[index] = livechatBanner[index].imageUrl;

    // Définissez showCropper sur true pour afficher l'image
    setShowCropper(true);

  };

  // Utilisez cette fonction dans `handleEditItem`


  const handleInputChange = (e, index = editingIndex) => {
    if (index != null) {
      // Mise à jour d'un item existant
      const updatedBanner = [...livechatBanner];
      updatedBanner[index] = { ...updatedBanner[index], [e.target.name]: e.target.value };
      setLivechatBanner(updatedBanner);
    } else {
      // Ajout d'un nouvel item
      setNewItem({ ...newItem, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (editingIndex != null) {
      setNewItem({ ...livechatBanner[editingIndex] });
      imageRefs.current[editingIndex] = livechatBanner[editingIndex].imageUrl;
    }
  }, [editingIndex, livechatBanner]);



  const handleImageChatBannerChange = (e, index) => {
    const file = e.target.files[0];
    setShowCropper(false);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageKey = index === livechatBanner.length ? 'new' : index;

        // Détruire l'ancienne instance de Cropper pour cet index si elle existe
        if (cropperInstancesChatBanner[imageKey]) {
          cropperInstancesChatBanner[imageKey].destroy();
          delete cropperInstancesChatBanner[imageKey];
        }

        imageRefs.current[imageKey] = reader.result;
        setShowCropper(true);
        setCropperInstancesChatBanner(prev => ({ ...prev })); // Force le re-rendu
        setIsCropped(true);
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    const setupCropper = (key, src) => {
      const imageElementId = key === 'new' ? 'image-cropper-new' : `image-cropper-${key}`;
      const imageElement = document.getElementById(imageElementId);

      if (src && imageElement) {
        const newCropper = new Cropper(imageElement, {
          aspectRatio: 640 / 100,
          //... autres options
        });
        setCropperInstancesChatBanner(prev => ({ ...prev, [key]: newCropper }));
      }
    };

    Object.keys(imageRefs.current).forEach(key => setupCropper(key, imageRefs.current[key]));

    return () => {
      Object.keys(cropperInstancesChatBanner).forEach(key => {
        if (cropperInstancesChatBanner[key]) {
          cropperInstancesChatBanner[key].destroy();
        }
      });
      setCropperInstancesChatBanner({});
    };
  }, [imageRefs.current, showCropper]);




  ///////////////////////

  const updateLivechatBannerInFirebase = async (updatedBanner) => {
    const dateTimeSubmission = new Date().toISOString();

    for (const [index, item] of updatedBanner.entries()) {
      // Suppression de l'ancienne image
      if (item.oldImageUrl && item.croppedImageBlob) {
        try {
          const oldImageRef = ref(storage, item.oldImageUrl);
          await deleteObject(oldImageRef);
        } catch (error) {
          console.error(`Erreur lors de la suppression de l’ancienne image ${index}:`, error);
        }
      }

      // Téléchargement de la nouvelle image

      if (item.croppedImageBlob) {
        try {
          // Options de compression
          const options = {
            maxSizeMB: 0.040, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
            maxWidthOrHeight: 1920, // Largeur/hauteur maximale (ajustez selon vos besoins)
            useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
            fileType: 'image/webp', // Convertir en WebP
          };

          // Compresser l'image rognée
          const compressedBlob = await imageCompression(item.croppedImageBlob, options);

          const fileName = item.imageFile ? item.imageFile.name : `image_${index}.png`;
          const storagePath = `mediaImages/${userEmail}/${dateTimeSubmission}_${index}_${fileName}`;
          const storageRef = ref(storage, storagePath);
          const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
          item.imageUrl = await getDownloadURL(snapshot.ref); // Mise à jour de l'URL dans l'objet
        } catch (error) {
          console.error(`Erreur lors du téléchargement de l'image ${index}:`, error);
          continue; // Passer à l'élément suivant en cas d'erreur
        }
      }
    }

    // Mise à jour de Firestore
    try {
      const articleRef = doc(db, "news", "media", mediaLanguage, userEmail);
      await updateDoc(articleRef, {
        livechatBanner: updatedBanner.map(item => ({
          name: item.name,
          imageUrl: item.imageUrl,
          link: item.link
        })),
        // ...autres champs
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour de Firestore:', error);
    }
  };

  const handleDeleteItemChatSponsor = async (index) => {
    // Copie de l'état actuel
    const updatedBanner = [...livechatBanner];

    // Suppression de l'élément du tableau
    const [deletedItem] = updatedBanner.splice(index, 1);

    // Suppression de l'image de Firebase Storage si nécessaire
    if (deletedItem.imageUrl) {
      try {
        const oldImageRef = ref(storage, deletedItem.imageUrl);
        await deleteObject(oldImageRef);
      } catch (error) {
        console.error(`Erreur lors de la suppression de l'image:`, error);
      }
    }

    // Mise à jour de Firestore
    try {
      const articleRef = doc(db, "news", "media", mediaLanguage, userEmail);
      await updateDoc(articleRef, {
        livechatBanner: updatedBanner.map(item => ({
          name: item.name,
          imageUrl: item.imageUrl,
          link: item.link
        }))
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour de Firestore:', error);
    }

    // Mettre à jour l'état local
    setLivechatBanner(updatedBanner);
  };



  const handleEdit = async (e) => { // MODIF DONNÉES MÉDIA
    e.preventDefault();

    ///// LOGO
    if (imageFileLogo && mediaLogo) {
      // Supprimez l'ancienne image de Firebase Storage
      try {
        // Créez une référence à l'ancienne image
        const oldImageRef = ref(storage, mediaLogo);
        await deleteObject(oldImageRef);
        console.log('Ancienne image supprimée avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression de l’ancienne image:', error);
      }
    }

    let imageUrl = "";
    const dateTimeSubmission = new Date().toISOString();

    if (imageFileLogo) {
      try {

        // Options de compression
        const options = {
          maxSizeMB: 0.010, // Taille maximale de 150 Ko
          maxWidthOrHeight: 1920, // Taille maximale de la largeur ou hauteur (en pixels)
          useWebWorker: true, // Utilisation des Web Workers pour améliorer les performances
          fileType: 'image/webp', // Convertir en WebP
        };

        // Compresser le fichier d'image
        const compressedFile = await imageCompression(imageFileLogo, options);

        const storagePath = `mediaImages/${userEmail}/${dateTimeSubmission}_${imageFileLogo.name}`;
        const storageRef = ref(storage, storagePath);

        const snapshot = await uploadBytes(storageRef, compressedFile);
        imageUrl = await getDownloadURL(snapshot.ref);
        setMediaLogo(imageUrl);
      } catch (error) {
        console.error("Erreur lors du téléchargement de l'image:", error);
        return; // Quittez la fonction si le téléchargement échoue
      }
    } else {
      console.log('Aucun fichier sélectionné');
      // return; // Quittez la fonction si aucun fichier n'est sélectionné
    }

    ///// Sponsors

    if (imageFileSponsors && mediaSponsors) {
      // Supprimez l'ancienne image de Firebase Storage
      try {
        // Créez une référence à l'ancienne image
        const oldImageRef = ref(storage, mediaSponsors);
        if (oldImageRef) {
          await deleteObject(oldImageRef);
          console.log('Ancienne image supprimée avec succès');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de l’ancienne image:', error);
      }
    }

    let imageUrlSponsors = "";


    if (imageFileSponsors) {

      if (croppedImageBlob) {
        try {
          // Options de compression
          const options = {
            maxSizeMB: 0.050, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
            maxWidthOrHeight: 1920, // Largeur/hauteur maximale (ajustez selon vos besoins)
            useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
            fileType: 'image/webp', // Convertir en WebP
          };

          // Compresser l'image rognée
          const compressedBlob = await imageCompression(croppedImageBlob, options);

          const storagePath = `mediaImages/${userEmail}/${dateTimeSubmission}_${imageFileSponsors.name}`;
          const storageRef = ref(storage, storagePath);

          const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
          imageUrlSponsors = await getDownloadURL(snapshot.ref);
          setMediaSponsors(imageUrlSponsors);
          // imageUrl est l'URL de l'image téléchargée
        } catch (error) {
          console.error("Erreur lors du téléchargement de l'image:", error);
          return; // Quittez la fonction si le téléchargement échoue
        }
      } else {
        setNoFileSelected(true);
        console.log('Veuillez confirmer le rognage de l’image');
        return;
      }


    } else {
      setNoFileSelected(true);
      console.log('Aucun fichier sélectionné');

    }

    try {
      const articleRef = doc(db, "news", "media", mediaLanguage, userEmail);
      // Utilisation de updateDoc au lieu de setDoc
      await updateDoc(articleRef, {
        name: mediaName,
        logo: imageUrl ? imageUrl : mediaLogo,
        sponsors: imageUrlSponsors ? imageUrlSponsors : mediaSponsors,
        twitterAccount: mediaTwitterAccount ? mediaTwitterAccount : null,
        instagramAccount: mediaInstagramAccount ? mediaInstagramAccount : null,
      });

    } catch (error) {
      console.error("Error updating document: ", error);
    }

    setIsEditing(false);
  };


  const handleChangePassword = async (e) => { //À TRADUIRE
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      alert(t("New passwords don't match!"));
      return;
    }

    if (!validatePassword(newPassword)) {
      alert(t("The password must contain at least 8 characters, an uppercase letter and a number."));
      return;
    }

    const user = auth.currentUser;

    if (user) {
      // Authentifier l'utilisateur avec l'ancien mot de passe
      const credential = EmailAuthProvider.credential(
        user.email,
        oldPassword
      );

      try {
        await reauthenticateWithCredential(user, credential);

        // Changer le mot de passe
        await updatePassword(user, newPassword);
        alert(t("Password successfully changed!"));
        // Masquer le formulaire après la réussite

      } catch (error) {
        alert(t("Error changing password: ") + error.message);
      }
      setFormSubmitted(true);
      setShowChangePassword(false);
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    }
  };

  // ARTICLES
  const formatDate = (isoString) => { // FORMATER LA DATE DE L'ARTICLE
    const date = new Date(isoString);
    return new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  const sortedArticles = articles.sort((a, b) => new Date(b.id) - new Date(a.id)); // TRIER LES ARTICLES PAR DATE

  const isFightAppLoggedIn = userEmail === "media@fightapp.fr" || userEmail === "mediaen@fightapp.fr";

  const deleteArticle = async (article) => {

    if (isFightAppLoggedIn) {
      if (writerData?.name !== article.writtenBy) {
        alert('Error: Access Denied');
        return;
      }
    }

    const articleId = article.id;

    if (mediaLanguage) {
      // Demander une confirmation avant de procéder à la suppression
      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?");
      if (isConfirmed) {
        try {
          // Créer une référence à l'article
          const articleRef = doc(db, "news", "media", mediaLanguage, userEmail, "articles", articleId);

          // Récupérer les données de l'article
          const docSnap = await getDoc(articleRef);

          if (docSnap.exists()) {
            const articleData = docSnap.data();
            const articleImage = articleData.image; // Récupérer l'URL de l'image

            // Supprimer l'article de Firestore
            await deleteDoc(articleRef);
            console.log('Article supprimé avec succès');

            // Tenter de supprimer l'image associée, si elle existe
            if (articleImage) {
              try {
                const oldImageRef = ref(storage, articleImage);
                await deleteObject(oldImageRef);
                console.log('Ancienne image supprimée avec succès');
              } catch (error) {
                console.error('Erreur lors de la suppression de l’ancienne image:', error);
              }
            }
          } else {
            console.log("Aucun article trouvé avec cet ID");
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de l'article: ", error);
        }

      } else {
        console.log("Suppression annulée.");
      }
    } else {
      console.error("Langue du média non trouvée.");
    }
  };

  // Display sponsors editing if the account is FightApp, > not allowing other medias to have sponsors for the moment
  // This can be easily hacked and change by people, so if people achieve to displays their own ads, it's probably because of that.
  // or they have been able to acces their document in firestore because the rules allowe it

  return (
    <div style={{
      display: 'flex', height: '100vh', flexDirection: 'column',
    }}>
      <div style={{
        height: '50px', width: '100%', display: 'flex',
        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', position: 'fixed', // Style clé pour fixer l'élément
        top: '0', // Positionner en haut de la page
        left: '0', // Aligner à gauche
        zIndex: 1000
      }}>
        <div style={{
          display: 'flex', alignItems: 'center'
        }}>
          <button onClick={() => navigate('/submitArticle')} style={{
            background: 'none', border: 'none', color: 'white',
            cursor: 'pointer', fontWeight: 'bolder', justifyContent: 'center', display: 'flex', marginLeft: 5, zIndex: 20
          }}>
            <FaArrowLeft size="1.3em" />
          </button>
          <img src={Logo} alt="Logo" style={{ height: '200px', marginLeft: -20, zIndex: 12 }} />

          {userEmail === "media@fightapp.fr" ?
            <h2 style={{ color: 'white', fontSize: '100%' }}>Français</h2>
            : userEmail === "mediaen@fightapp.fr" ? <h2 style={{ color: 'white', fontSize: '100%' }}>English</h2> : null}
        </div>


        <div style={{
          display: 'flex', alignItems: 'center', marginRight: '2%',
        }}>
          <button onClick={handleLogout} style={{
            background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
            borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder',
          }}>

            {t('Log out')}
          </button>
        </div>

      </div>


      <div style={{
        display: 'flex', flexDirection: 'column', fontFamily: "'Arial', sans-serif",
        borderRadius: '15px', width: '90%', alignItems: 'flex-start', padding: '30px',
        marginTop: '100px'
      }}>
        <h2 style={{ color: '#ff3030', margin: '0 0 20px 0', fontSize: '1.8em' }}>{t('Your data')}</h2>

        <form onSubmit={handleEdit}>
          {/* Nom du média */}
          <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>{t('Media name')}:</p>
          {isEditing ? (
            <input
              type="text"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
              style={{
                width: '50%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0',
                borderRadius: '7px', outline: 'none', fontFamily: "'Arial', sans-serif",
                transition: 'border 0.3s ease-in-out', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
              }}
            />
          ) : (
            <p style={{ margin: '0 0 15px 0' }}>{mediaData.name}</p>
          )}

          {/* Logo */}
          <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>Logo:</p>
          {isEditing ? (
            <div style={{ width: '50%', marginBottom: '15px' }}>
              <label htmlFor="file-upload" style={{ marginBottom: '5px', display: 'block' }}>
                {t('Choose another image')}:
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleImageChange}
                style={{
                  width: '100%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0',
                  borderRadius: '7px', outline: 'none', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                }}
              />
            </div>
          ) : (
            <div style={{
              width: '90%', margin: '5px 0', display: 'flex', justifyContent: 'center'
            }}>
              <img
                src={mediaData.logo}
                alt="Logo"
                style={{
                  maxWidth: '100%', maxHeight: '150px', marginTop: '10px'
                }} />
            </div>
          )}

          {/* Réseaux sociaux */}
          <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>{t('Social networks to display in the article:')}</p>
          {/* Twitter */}
          <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>{t('Twitter link')}:</p>
          {isEditing ? (
            <input
              type="text"
              value={mediaTwitterAccount}
              onChange={(e) => setMediaTwitterAccount(e.target.value)}
              style={{
                width: '50%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0',
                borderRadius: '7px', outline: 'none', fontFamily: "'Arial', sans-serif",
                transition: 'border 0.3s ease-in-out', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
              }}
            />
          ) : (
            <p style={{ margin: '0 0 15px 0' }}>{mediaData.twitterAccount}</p>
          )}

          {/* Instagram */}
          <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>{t('Instagram link')}:</p>
          {isEditing ? (
            <input
              type="text"
              value={mediaInstagramAccount}
              onChange={(e) => setMediaInstagramAccount(e.target.value)}
              style={{
                width: '50%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0',
                borderRadius: '7px', outline: 'none', fontFamily: "'Arial', sans-serif",
                transition: 'border 0.3s ease-in-out', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
              }}
            />
          ) : (
            <p style={{ margin: '0 0 15px 0' }}>{mediaData.instagramAccount}</p>
          )}

          {/* SPONSORS */}
          {isFightAppLoggedIn ?
            <>
              <div style={{ // SPONSORS
                width: '100%', marginBottom: '10px',
              }}>
                <label htmlFor="file-upload" style={{ display: 'block', marginBottom: '10px', fontWeight: 'bold', color: '#333', margin: '15px 0 10px' }}>
                  {t("Sponsor banner")} :
                </label>
                <p style={{ color: '#333', margin: '15px 0 10px' }}>{t('Recommended size')}: 1200x400 px</p>
                {isEditing ?
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    onChange={handleImageChangeSponsors}
                    style={{
                      width: '50%', padding: '10px 15px', border: '1px solid #cccccc',
                      borderRadius: '5px', outline: 'none',
                    }} />
                  : null}
                {noFileSelected ? <label style={{ color: 'red', fontSize: 13 }}>{t('No files selected')}</label> : null}
              </div>

              {isEditing ? (
                <>
                  {!noFileSelected ?  // SPONSORS
                    <button style={{
                      width: '50%', marginBottom: '10px', marginTop: '10px', padding: '10px 20px', border: 'none', backgroundColor: isHovered || isActive ? '#1f1f1f' : '#252525',
                      color: 'white', borderRadius: '5px', marginTop: '20px', cursor: 'pointer', opacity: isActive ? 0.6 : 1,
                      outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                    }} type="button" onClick={handleCropConfirm}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onMouseDown={() => setIsActive(true)}
                      onMouseUp={() => setIsActive(false)} >{t('Confirm cropping')}</button>
                    : null}

                  {imageRef && !noFileSelected ? // SPONSORS
                    <div style={{ width: '500px', height: '500px', overflow: 'hidden' }}>
                      <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Preview" />
                    </div>
                    : null}
                </>
              ) : (
                <div style={{
                  width: '90%', margin: '5px 0', display: 'flex', justifyContent: 'center'
                }}>
                  <img
                    src={mediaData.sponsors}
                    alt="Logo"
                    style={{
                      maxWidth: '100%', maxHeight: '150px', marginTop: '10px'
                    }} />
                </div>
              )}
            </>
            : null}

          {/* Bouton de sauvegarde */}
          {isEditing && (
            <button type="submit" style={{
              padding: '12px 25px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '30px', marginTop: '20px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}>
              {t('Save updates')}
            </button>
          )}

          {/* Bouton d'édition */}
          {!isEditing && (
            <button onClick={handleEditClick} style={{
              padding: '12px 25px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '30px', marginTop: '20px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}>
              {t('Update')}
            </button>
          )}

        </form>
      </div>

      {showChangePassword ? ( // MODIFICATION MOT DE PASSE 
        <div style={{
          display: 'flex', flexDirection: 'column', fontFamily: "'Arial', sans-serif",
          borderRadius: '10px', width: '400px', alignItems: 'flex-start', padding: '30px'
        }}>
          <form onSubmit={handleChangePassword}>

            <h2 style={{ color: '#ff3030', margin: '0 0 10px 0' }}>{t('Change password')}</h2>

            <input style={{
              width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
              borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
            }} type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}
              placeholder={t("Old Password")} required />



            <input style={{
              width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
              borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
            }} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Nouveau mot de passe" required />

            {formSubmitted && !passwordIsValid ? <h2 style={{ color: 'red', margin: '0 0 10px 0', fontSize: 12 }}>{t("The password must contain at least 8 characters, a capital letter and a number.")}</h2> :
              <h2 style={{ color: 'black', margin: '0 0 10px 0', fontSize: 12 }}>{t("The password must contain at least 8 characters, a capital letter and a number.")}</h2>
            }
            <input style={{
              width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
              borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
            }} type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} placeholder={t("Confirm the new password")} required />

            <button style={{
              padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
            }} type="submit">{t('Change password')}</button>

          </form>
          <button style={{
            padding: '10px 20px', border: 'none',
            color: '#ff3030', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
          }} onClick={() => setShowChangePassword(false)}>{t('Cancel')}</button>
        </div>
      ) : (
        <div style={{
          display: 'flex', flexDirection: 'column', fontFamily: "'Arial', sans-serif",
          borderRadius: '10px', width: '400px', alignItems: 'flex-start', padding: '30px'
        }}>
          <button style={{
            padding: '10px 20px', border: 'none',
            color: '#ff3030', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
          }} onClick={() => setShowChangePassword(true)}>{t('Change password')}</button>
        </div>
      )}

      {/* LIVE CHAT SPONSORS */}
      {isFightAppLoggedIn ?
        <>
          <div style={{}}>

            <p style={{ fontWeight: 'bold', color: 'black', margin: '15px 0 10px', marginLeft: '20px', }}>{t('Sponsors banner in Live chat')}</p>

            <button onClick={() => setShowInputs(true)}
              style={{
                height: '40px', width: '200px', border: 'none', backgroundColor: '#ff3030',
                color: 'white', borderRadius: '5px', marginTop: '20px', marginLeft: '20px', cursor: 'pointer',
                outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)', fontSize: 15
              }}>{t('Add a sponsor')}</button>

            {showInputs && (
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px', marginLeft: '20px' }}>{t("Sponsor's name")}:</p>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={newItem.name}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    width: '50%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0', marginTop: '10px',
                    borderRadius: '7px', outline: 'none', fontFamily: "'Arial', sans-serif",
                    transition: 'border 0.3s ease-in-out', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginLeft: '20px',
                  }}
                />

                <input type="file" onChange={(e) => handleImageChatBannerChange(e, editingIndex !== null ? editingIndex : livechatBanner.length)}
                  style={{ marginLeft: '20px', marginTop: '20px', width: '40%' }} />



                {
                  showCropper && (
                    <div style={{ marginLeft: '20px', marginTop: '20px', width: '90%' }}>
                      <img
                        id={editingIndex !== null ? `image-cropper-${editingIndex}` : 'image-cropper-new'}
                        src={editingIndex !== null ? imageRefs.current[editingIndex] : imageRefs.current['new']}
                        alt="Crop me"
                        style={{ width: '90%' }}
                      />
                    </div>
                  )
                }

                <p style={{ fontWeight: 'bold', color: '#333', margin: '15px 0 10px', marginLeft: '20px' }}>{t("Sponsor's link")}:</p>
                <input
                  type="text"
                  name="link"
                  placeholder="Link"
                  value={newItem.link}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    width: '50%', padding: '12px', border: '2px solid #ff3030', margin: '5px 0', marginTop: '10px',
                    borderRadius: '7px', outline: 'none', fontFamily: "'Arial', sans-serif",
                    transition: 'border 0.3s ease-in-out', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginLeft: '20px',
                  }}
                />

                {editingIndex != null ? (
                  <button onClick={handleUpdateItem} style={{
                    padding: '12px 25px', border: 'none', backgroundColor: 'black', width: '50%',
                    color: 'white', borderRadius: '10px', marginTop: '20px', cursor: 'pointer',
                    outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginLeft: '20px',
                  }}>{t('Save Item')}</button>
                ) : (
                  <button onClick={handleAddItem} style={{
                    padding: '12px 25px', border: 'none', backgroundColor: 'black', width: '50%',
                    color: 'white', borderRadius: '10px', marginTop: '20px', cursor: 'pointer',
                    outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginLeft: '20px',
                  }}>{t('Add Item')}</button>
                )}
              </div>
            )}

            {editingIndex === null ?
              <ul style={{}}>
                {livechatBanner.map((item, index) => (

                  <li key={index} style={{
                    display: 'flex', alignItems: 'center', marginBottom: '20px', marginLeft: '-20px'
                  }}>
                    <button onClick={() => handleDeleteItemChatSponsor(index)}
                      style={{
                        height: '40px', width: '40px', border: 'none', backgroundColor: '#383838',
                        color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                      }}>
                      <FaTrash />
                    </button>

                    <button onClick={(e) => handleEditItem(index)}
                      style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                        padding: '10px', border: '1px solid #161616', backgroundColor: '#f5f5f5',
                        color: 'black', borderRadius: '5px', cursor: 'pointer',
                        width: '150px'
                      }}>
                      {item.name}
                    </button>

                  </li>

                ))}
              </ul> : <button onClick={() => {
                setEditingIndex(null); setNewItem({ name: '', image: null, link: '' }); setEditingIndex(null); setShowInputs(false);
              }} style={{
                padding: '12px 25px', border: 'none', backgroundColor: '#fd2121', width: '50%',
                color: 'white', borderRadius: '10px', marginTop: '20px', cursor: 'pointer',
                outline: 'none', transition: 'background-color 0.3s, transform 0.3s', fontWeight: 'bold',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginLeft: '20px',
              }}>{t('Back')}</button>}

          </div>
        </> : null}

      <div style={{ // ARTICLES

      }}>
        <h2 style={{ fontWeight: 'bold', color: 'black', margin: '15px 0 10px', marginLeft: '20px', }}>{t('Your articles')}:</h2>
        <ul style={{ marginLeft: '-20px', }}>
          {sortedArticles.map((article, index) => (
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', }} key={index}>
              <button onClick={() => deleteArticle(article)}
                style={{
                  padding: '10px 10px', border: 'none', backgroundColor: '#383838',
                  color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                  outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                }}>
                <FaTrash />
              </button>

              <Link to={`/articles/${article.id}`} style={{ textDecoration: 'none' }}>
                <button style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                  padding: '10px', border: '1px solid #161616', backgroundColor: '#f5f5f5',
                  color: 'black', borderRadius: '5px', cursor: 'pointer',
                  width: '100%'
                }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#e2e2e2'} // Couleur au survol
                  onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f5f5f5'} // Couleur d'origine
                >
                   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                    <span style={{ marginRight: 10 }}>({formatDate(article.id)})</span>
                    <span style={{ marginRight: 10, }}>{article.writtenBy}</span>
                    </div>
                    
                   
                   
                    <span style={{ color: 'black', fontWeight: 'bold' }}> {article.title}</span>
                    <span style={{ marginLeft: '10px', color: 'black' }}>➔</span>
                </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function validatePassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasDigit = /\d/.test(password);


  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasDigit
  );
}

export function SubmitArticle() {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
  const [mediaLanguage, setMediaLanguage] = useState(localStorage.getItem('mediaLanguage') || null);
  const [writerData, setWriterData] = useState(JSON.parse(localStorage.getItem("writerData")) || null);
  const [orgs, setOrgs] = useState([])
  const [topic, setTopic] = useState("");
  const [imageCredit, setImageCredit] = useState("");
  const [writtenBy, setWrittenBy] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const [draftArticles, setDraftArticles] = useState([]);
  //const [noFileSelected, setNoFileSelected] = useState(false);
  const [draftTag, setDraftTag] = useState("");

  const [userLang, setUserLang] = useState(navigator.language.slice(0, 2) || 'en');
  const [isLanguageMediaInList, setIsLanguageMediaInList] = useState(false);
  const [sponsors, setSponsors] = useState("");

  const [short, setShort] = useState(false);
  const shorts = [{ value: false, label: "NO" }, { value: true, label: "YES" }];
  const languages = ['en', 'fr', 'es', 'de', 'pt', 'ru', 'it', 'pl', 'cs'];

  useEffect(() => {
    setUserLang(navigator.language.slice(0, 2) || 'en');
    i18n.changeLanguage(userLang);
  }, []);

  useEffect(() => { // LANGUE USER
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {

      setUserEmail(user.email);
      localStorage.setItem('userEmail', user.email);


      // Récupérer la langue du média
      const fetchMediaLanguage = async () => {
        for (let lang of languages) {
          const mediaDocRef = doc(db, "news", "media", lang, user.email);
          const mediaDoc = await getDoc(mediaDocRef);
          if (mediaDoc.exists()) {
            const language = mediaDoc.data().language
            const isLanguageMediaInList = mediaDoc.data().isLanguageMediaInList
            const sponsors = mediaDoc.data().sponsors

            setMediaLanguage(language);
            setIsLanguageMediaInList(isLanguageMediaInList);
            setSponsors(sponsors);
            localStorage.setItem('mediaLanguage', language);
            break;
          }
        }
      };

      fetchMediaLanguage();
    }
  }, []);

  useEffect(() => { // RÉCUPÉRER ORGA POUR SUJET DE L'ARTICLE
    const fetchOrgs = async () => {

      const orgsDocRef = doc(db, "fightData", "organisations");

      try {
        const orgsDocSnap = await getDoc(orgsDocRef);

        if (orgsDocSnap.exists()) {
          const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
          setOrgs(listOrgasNames);
        } else {
          console.log("Document 'organisations' non trouvé.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document:", error);
      }
    };

    fetchOrgs();
  }, []);



  const [accountIsLoading, setAccountIsLoading] = useState(false); // lors du Switch Français/English account (only for FightApp account)

  useEffect(() => { // RÉCUPÉRATION DONNÉES BROUILLONS

    if (mediaLanguage && userEmail && !accountIsLoading) {

      const articlesRef = collection(db, "news", "media", mediaLanguage, userEmail, "drafts");
      const unsubscribeArticles = onSnapshot(articlesRef, (querySnapshot) => {
        const articlesList = [];
        querySnapshot.forEach((doc) => {
          const articleData = doc.data();
          articlesList.push({ ...articleData, id: doc.id });
        });

        setDraftArticles(articlesList);
      });
      // Assurez-vous de vous désabonner de l'écouteur lorsque le composant est démonté
      return () => {
        unsubscribeArticles();
      }
    }
  }, [auth, db, mediaLanguage, userEmail, accountIsLoading]);



  ///////// IMAGE
  // const [imageFile, setImageFile] = useState(null);
  /*
    const handleImageChange = (e) => {
      const file = e.target.files[0];
  
      if (file) {
        setImageFile(file);
        setNoFileSelected(false);
      }
    };
    */
  const fileInputRef = useRef();// sélectionneur d'image


  //// image 
  const [imageFile, setImageFile] = useState(null);
  const [noFileSelected, setNoFileSelected] = useState(true);

  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const imageRef = useRef();
  const cropperRef = useRef();

  // Gestionnaire pour confirmer le rognage
  const handleCropConfirm = () => {
    cropperRef.current.getCroppedCanvas().toBlob((blob) => {
      setCroppedImageBlob(blob); // Stocker le blob pour la télécharger plus tard
    }, 'image/webp');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);
      setNoFileSelected(false);

      // Lire l'image et l'afficher pour le recadrage
      const reader = new FileReader();
      reader.onload = (e) => {
        imageRef.current.src = e.target.result;
        if (cropperRef.current) {
          cropperRef.current.destroy(); // Détruire l'instance précédente
        }
        cropperRef.current = new Cropper(imageRef.current, {
          aspectRatio: short ? 1080 / 1350 : 1200 / 628, // Ratio d'aspect souhaité
          //... autres options
        });
      };
      reader.readAsDataURL(file);
    }
  };
  ////////////
  const handleSubmit = async (e) => {
    e.preventDefault();

    const dateTimeSubmission = new Date().toISOString();


    let imageUrl = ""; // Utilisez cette variable pour stocker l'URL de l'image téléchargée

    if (mediaLanguage) {

      if (imageFile) {
        if (croppedImageBlob) {
          try {
            // Options de compression
            const options = {
              maxSizeMB: 0.10, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
              maxWidthOrHeight: 1920, // Largeur/hauteur maximale (ajustez selon vos besoins)
              useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
              fileType: 'image/webp', // Convertir en WebP
            };

            // Compresser l'image rognée
            const compressedBlob = await imageCompression(croppedImageBlob, options);

            const storagePath = `mediaImages/${userEmail}/${dateTimeSubmission}_${imageFile.name}`;
            const storageRef = ref(storage, storagePath);

            const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
            imageUrl = await getDownloadURL(snapshot.ref);
          } catch (error) {
            console.error("Erreur lors du téléchargement de l'image:", error);
            return; // Quittez la fonction si le téléchargement échoue
          }
        } else {
          setNoFileSelected(true);
          console.log('Veuillez confirmer le rognage de l’image');
          return;
        }
      } else {
        setNoFileSelected(true);
        console.log('Aucun fichier sélectionné');
        return; // Quittez la fonction si aucun fichier n'est sélectionné
      }

      const writerName = writerData?.name ? writerData?.name : writtenBy ? writtenBy : null;
      try {

        const articleRef = doc(db, "news", "media", mediaLanguage, userEmail, "articles", dateTimeSubmission);
        await setDoc(articleRef, {
          title,
          content,
          short: short ? true : false,
          image: imageUrl, // Utilisez l'URL de l'image téléchargée
          topic,
          topNews: '',
          imageCredit: imageCredit ? imageCredit : null,
          writtenBy: writerName,
          sponsors: sponsors ? sponsors : null // Assurez-vous que cette valeur est correcte ou supprimez-la si non nécessaire
        });

        // Query the "drafts" collection for the document with the matching draftTag
        const draftsRef = collection(db, "news", "media", mediaLanguage, userEmail, "drafts");
        const querySnapshot = await getDocs(query(draftsRef, where("draftTag", "==", draftTag)));

        // Since draftTag is unique, there should only be one document
        const draftDoc = querySnapshot.docs[0];
        if (draftDoc) {
          const draftRef = doc(db, "news", "media", mediaLanguage, userEmail, "drafts", draftDoc.id);
          await deleteDoc(draftRef);
        }

        if (!isLanguageMediaInList) {
          // Récupérer la référence du document 'media' dans la collection 'news'
          const mediaDocRef = doc(db, "news", "media");

          // Récupérer le document 'media' pour accéder à la liste des langues 'listLanguagesNews'
          const mediaDocSnapshot = await getDoc(mediaDocRef);

          if (mediaDocSnapshot.exists()) {
            // Récupérer la liste 'listLanguagesNews'
            let listLanguagesNews = mediaDocSnapshot.data().listLanguagesNews || [];

            // Vérifier si 'mediaLanguage' est dans la liste
            if (listLanguagesNews.includes(mediaLanguage)) {
              // Mettre à jour le document utilisateur avec isLanguageMediaInList = true
              const userDocRef = doc(db, "news", "media", mediaLanguage, userEmail);
              await updateDoc(userDocRef, {
                isLanguageMediaInList: true
              });
            } else {
              // Ajouter 'mediaLanguage' à la liste
              listLanguagesNews.push(mediaLanguage);

              // Mettre à jour la liste 'listLanguagesNews' dans le document 'media'
              await updateDoc(mediaDocRef, {
                listLanguagesNews: listLanguagesNews
              });

              // Mettre à jour le document utilisateur avec isLanguageMediaInList = true
              const userDocRef = doc(db, "news", "media", mediaLanguage, userEmail);
              await updateDoc(userDocRef, {
                isLanguageMediaInList: true
              });
            }
          } else {
            console.error("Le document 'media' n'existe pas.");
          }
        }


        // Réinitialisez les états après la soumission réussie
        setTitle("");
        setTopic("");
        setContent("");
        setImageFile(null);
        fileInputRef.current.value = "";
        setImageCredit("");
        setWrittenBy("");
        if (editorRef.current && editorRef.current.easyMDEInstance) {
          editorRef.current.easyMDEInstance.value("");// Réinitialise la valeur
        }
        alert(t("Article submitted successfully!"));
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      console.error("Langue du média non trouvée.");
    }
  };

  // MISE EN BROUILLONS
  const [draftsVisible, setDraftsVisible] = useState(false);
  const [dateTimeSubmission, setDateTimeSubmission] = useState(new Date().toISOString());

  const handleSetDraftsClick = (article) => { // RECUP BROUILLON
    setTitle(article.title);
    setTopic(article.topic);
    setImageCredit(article.imageCredit);
    setWrittenBy(article.writtenBy);
    setDraftTag(article.draftTag);
    setDateTimeSubmission(article.id);

    // Vérifiez si l'instance EasyMDE existe et mettez à jour son contenu
    if (editorRef.current && editorRef.current.easyMDEInstance) {
      editorRef.current.easyMDEInstance.value(article.content);
    }

    setContent(article.content); // Mettre à jour l'état React
  };

  const handleSaveInDrafts = async () => {

    //const dateTimeSubmission = new Date().toISOString();

    if (mediaLanguage) {

      const writerName = writerData?.name ? writerData?.name : writtenBy ? writtenBy : null;
      try {
        const articleRef = doc(db, "news", "media", mediaLanguage, userEmail, "drafts", dateTimeSubmission);
        await setDoc(articleRef, {
          title,
          content,
          topic,
          imageCredit: imageCredit ? imageCredit : null,
          writtenBy: writerName,
          draftTag: `${dateTimeSubmission}${title}`
        });

        // Réinitialisez les états après la soumission réussie
        setTitle("");
        setTopic("");
        setContent("");
        setImageCredit("");
        setWrittenBy("");
        if (editorRef.current && editorRef.current.easyMDEInstance) {
          editorRef.current.easyMDEInstance.value("");// Réinitialise la valeur
        }
        alert(t("Article saved successfully!"));
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      console.error("Langue du média non trouvée.");
    }
  };

  const isFightAppLoggedIn = userEmail === "media@fightapp.fr" || userEmail === "mediaen@fightapp.fr";

  const deleteDraft = async (article) => {

    if (isFightAppLoggedIn) {
      if (writerData?.name !== article.writtenBy) {
        alert('Error: Access Denied');
        return;
      }
    }

    const draftId = article.id;

    if (mediaLanguage) {
      // Demander une confirmation avant de procéder à la suppression
      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce brouillon ?");
      if (isConfirmed) {
        try {
          const draftRef = doc(db, "news", "media", mediaLanguage, userEmail, "drafts", draftId);
          await deleteDoc(draftRef);
          // Afficher un message de succès ou mettre à jour l'état ici, si nécessaire
        } catch (error) {
          console.error("Erreur lors de la suppression du brouillon: ", error);
        }
      } else {
        console.log("Suppression annulée.");
      }
    } else {
      console.error("Langue du média non trouvée.");
    }
  };

  const countWords = text => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const handleAccountClick = () => {
    navigate("/account", { state: { mediaLanguage, userEmail } });

  };

  const sortedDraftArticles = draftArticles.sort((a, b) => new Date(b.id) - new Date(a.id)); // TRIER LES ARTICLES PAR DATE

  const formatDate = (isoString) => { // FORMATER LA DATE DE L'ARTICLE
    const date = new Date(isoString);
    return new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  

  ////// SOULIGNAGE ET MISE EN GRAS
  // Display sponsors editing if the account is FightApp, > not allowing other medias to have sponsors for the moment
  // This can be easily hacked and change by people, so if people achieve to displays their own ads, it's probably because of that.
  // or they have been able to acces their document in firestore because the rules allowe it
  const editorRef = useRef(null);

  useEffect(() => { // MARKDOWN
    if (!editorRef.current) return;

    const toolbar = [
      "bold",
      "italic",
      "heading",
      "|",
      "quote",
      ...(isFightAppLoggedIn
        ? [{
          name: "insertImageMarker",
          action: editor => {
            const cursorPosition = editor.codemirror.getCursor();
            editor.codemirror.replaceRange('[[SPONSOR]]', cursorPosition);
          },
          className: "fa fa-image", // Utilisez une icône appropriée
          title: t("Insert your sponsor's image"),
        }]
        : [])
    ];

    const instance = new EasyMDE({
      element: editorRef.current,
      toolbar,
      initialValue: content,
    });


    instance.codemirror.on("change", () => {
      setContent(instance.value());
    });

    // Stocker l'instance dans une référence pour y accéder plus tard
    editorRef.current.easyMDEInstance = instance;

    return () => {
      instance.toTextArea();
    };
  }, [isFightAppLoggedIn]);

  // Switch Français/English account (only for FightApp account)
  const [frenchOrEnglishAccount, setFrenchOrEnglishAccount] = useState(userEmail === "media@fightapp.fr" ? 'fr' : 'en');

  const switchAccount = async (language) => {

    const email = language === 'fr' ? process.env.REACT_APP_FIGHTAPP_FR_EMAIL : process.env.REACT_APP_FIGHTAPP_EN_EMAIL;
    const password = process.env.REACT_APP_FIGHTAPP_PASSWORD;

    await signInWithEmailAndPassword(auth, email, password);

    localStorage.setItem('email', email);

    const newAuth = getAuth();
    const user = newAuth.currentUser;
    setUserEmail(user.email);
    setMediaLanguage(language)
    localStorage.setItem('userEmail', user.email);
    localStorage.setItem('mediaLanguage', language);

    setTitle("");
    setTopic("");
    setContent("");

    if (editorRef.current && editorRef.current.easyMDEInstance) {
      editorRef.current.easyMDEInstance.value("");// Réinitialise la valeur
    }

    setAccountIsLoading(false);
  };

  const LoadingSpinner = () => {
    return (
      <div className="spinner"></div>
    );
  };

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}>
      <div style={{
        height: '50px', width: '100%', display: 'flex',
        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', position: 'fixed', // Style clé pour fixer l'élément
        top: '0', // Positionner en haut de la page
        left: '0', // Aligner à gauche
        zIndex: 1000
      }}>
        <div style={{
          display: 'flex', alignItems: 'center'
        }}>
          <img src={Logo} alt="Logo" style={{ height: '200px', marginLeft: -10 }} />
          {userEmail === "media@fightapp.fr" ?
            <h2 style={{ color: 'white', fontSize: '100%' }}>Français</h2>
            : userEmail === "mediaen@fightapp.fr" ? <h2 style={{ color: 'white', fontSize: '100%' }}>English</h2> : null}
        </div>

        <div style={{
          display: 'flex', alignItems: 'center', marginRight: '2%',
        }}>

          <button onClick={handleAccountClick} style={{
            background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
            borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder', marginRight: 10
          }}>
            <FaUser style={{ marginRight: '5px' }} />
            {t('Account')}
          </button>
        </div>
      </div>

      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
        fontFamily: "'Arial', sans-serif", justifyContent: 'center',
        borderRadius: '10px', width: '100%', margin: '50px auto', boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      }}>

        <h2 style={{ color: '#fd2121', marginBottom: '20px', fontSize: '100%' }}>{t('Welcome to the FightApp media platform')}</h2>

        <button
          onClick={() => {
            navigate("/PdfGuidelines", { state: { userLang: userLang } });
          }}
          style={{
            padding: '10px 20px', border: 'none', backgroundColor: '#252525',
            color: 'white', borderRadius: '5px', cursor: 'pointer',
            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginBottom: '20px'
          }}>
          {t('Guidelines and FightApp Community Rules')}
        </button>

        {isFightAppLoggedIn ? // Bouton switch Français/English
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',

          }}>
            <button
              onClick={() => {
                setFrenchOrEnglishAccount('fr');
                setAccountIsLoading(true);
                switchAccount('fr');
              }}
              style={{
                padding: '10px 20px', border: 'none', backgroundColor: frenchOrEnglishAccount === 'fr' ? '#407cff' : 'white', marginRight: '20px',
                color: frenchOrEnglishAccount === 'fr' ? 'white' : '#252525', borderRadius: '5px', cursor: 'pointer',
                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginBottom: '20px',
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
              }}>
              Français
            </button>

            <button
              onClick={() => {
                setFrenchOrEnglishAccount('en');
                setAccountIsLoading(true);
                switchAccount('en');
              }}
              style={{
                padding: '10px 20px', border: 'none', backgroundColor: frenchOrEnglishAccount === 'en' ? '#407cff' : 'white',
                color: frenchOrEnglishAccount === 'en' ? 'white' : '#252525', borderRadius: '5px', cursor: 'pointer',
                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginBottom: '20px',
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
              }}>
              English
            </button>
          </div>
          : null}

        {accountIsLoading ?
          <LoadingSpinner />
          : null}

        <h2 style={{ color: 'black', marginBottom: '20px', fontSize: '150%' }}>{t('Publish an article/news')}</h2>

        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '100%', padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '8px', boxSizing: 'border-box' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

              {/* SHORT */}
              <div>
                <label style={{ display: 'block', marginBottom: '10px' }}>{t('Short news')} ?</label>
                <select
                  value={short}
                  onChange={(e) => setShort(e.target.value === 'true')}
                  required
                  style={{
                    width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                    borderRadius: '5px', outline: 'none', backgroundColor: 'white',
                  }}>
                  <option value="">{t('YES OR NO')}</option>
                  {shorts.map((short, index) => (
                    <option key={index} value={short.value}>
                      {short.label}
                    </option>
                  ))}
                </select>

              </div>

              {/* Titre */}
              {!short ?
                <div>
                  <label style={{ display: 'block', marginBottom: '10px' }}>{t('Title')}:</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    style={{
                      width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                      borderRadius: '5px', outline: 'none',
                    }}
                  />
                </div>
                : null}

              {/* Sujet */}
              <div>
                <label style={{ display: 'block', marginBottom: '10px' }}>{t('Subject')}:</label>
                <select
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}

                  style={{
                    width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                    borderRadius: '5px', outline: 'none', backgroundColor: 'white',
                  }}>
                  <option value="">{t('Select a topic')}</option>
                  {orgs.map((org, index) => (
                    <option key={index} value={org}>{org}</option>
                  ))}
                </select>
              </div>

              <label style={{ display: 'block', marginTop: '10px' }}>{t("You can place the sponsor's image in the middle of the text or at the end by clicking on the image icon.")}</label>

              {/* Contenu */}
              <div style={{ alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                  <label style={{ display: 'block', marginBottom: '10px' }}>{t('Content')}:</label>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ border: '1px solid #cccccc', borderRadius: '5px', padding: '10px' }}>
                        <textarea ref={editorRef} />
                      </div>
                    </div>

                  </div>

                </div>

                <label style={{
                  display: 'block', fontWeight: 'bold', color: '#333333',
                  fontSize: '15px'
                }}>{t('WORDS')} : {countWords(content)}</label>


              </div>

              {/* Image à télécharger */}
              <div>
                <label htmlFor="file-upload" style={{ display: 'block', marginBottom: '10px' }}>
                  {t('Image to download')} :
                </label>
                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleImageChange}
                  style={{
                    width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                    borderRadius: '5px', outline: 'none',
                  }} />
                {noFileSelected ? <label style={{ color: 'red', fontSize: 13 }}>{t('No files selected')}</label> : null}
              </div>

              {!noFileSelected ?
                <button style={{ width: '50%', marginBottom: '10px' }} type="button" onClick={handleCropConfirm}>{t('Confirm cropping')}</button>
                : null}

              {imageRef && !noFileSelected ?
                <div style={{ width: '500px', height: '500px', overflow: 'hidden' }}>
                  <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Preview" />
                </div>
                : null}

              {/* Crédit image */}
              <div>
                <label style={{ display: 'block', marginBottom: '10px' }}>{t('Image credit (if necessary)')}:</label>
                <input
                  type="text"
                  value={imageCredit}
                  onChange={(e) => setImageCredit(e.target.value)}
                  style={{
                    width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                    borderRadius: '5px', outline: 'none',
                  }} />
              </div>

              {/* Crédit écriture */}
              <div>
                <label style={{ display: 'block', marginBottom: '10px' }}>{t('Written by (if necessary)')}:</label>
                <input
                  type="text"
                  value={writtenBy}
                  onChange={(e) => setWrittenBy(e.target.value)}
                  style={{
                    width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                    borderRadius: '5px', outline: 'none',
                  }} />
              </div>
            </div>
          </div>

          {/* Bouton Publier */}
          <button
            type="submit"
            style={{
              padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
              color: 'white', borderRadius: '25px', marginTop: '20px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s ease-in-out', fontWeight: 'bolder',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}>
            {t('Publish')}
          </button>
        </form>

        {/* Boutons de gestion des brouillons */}
        <div style={{ display: 'flex', marginTop: '30px', width: '100%', justifyContent: 'space-between' }}>
          <button
            onClick={() => handleSaveInDrafts()}
            style={{
              padding: '10px 20px', border: 'none', backgroundColor: '#407cff', width: '50%',
              color: 'white', borderRadius: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
            }}>
            {t('Save to drafts')}
          </button>

          <button onClick={() => setDraftsVisible(!draftsVisible)}
            style={{
              padding: '10px 20px', border: 'none', backgroundColor: '#383838', width: '35%',
              color: 'white', borderRadius: '10px', cursor: 'pointer',
              outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
            }}>
            {draftsVisible ? t("Close") : t("View drafts")}
          </button>
        </div>

        {/* Section Brouillons */}
        {draftsVisible ?
          <div style={{
            padding: '30px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '8px', marginTop: '20px',
          }}>
            <h2 style={{ color: '#ff3030', margin: '0 0 10px 0' }}>{t('Drafts')}:</h2>
            <ul>
              {sortedDraftArticles.map((article, index) => (
                <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '500px', marginBottom: '10px', }} key={index}>
                  <button onClick={() => deleteDraft(article)}
                    style={{
                      padding: '10px 10px', border: 'none', backgroundColor: '#383838',
                      color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                      outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                    }}>
                    <FaTrash />
                  </button>

                  <button onClick={() => handleSetDraftsClick(article)}
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                      padding: '10px', border: '1px solid #161616', backgroundColor: '#f5f5f5',
                      color: 'black', borderRadius: '5px', cursor: 'pointer',
                    }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#e2e2e2'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f5f5f5'}
                  >
                   

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                    <span style={{ marginRight: 10 }}>({formatDate(article.id)})</span>
                    <span style={{ marginRight: 10, }}>{article.writtenBy}</span>
                    </div>
                    
                   
                   
                    <span style={{ color: 'black', fontWeight: 'bold' }}> {article.title}</span>
                    <span style={{ marginLeft: '10px', color: 'black' }}>➔</span>
                  </button>


                </li>
              )
              )}
            </ul>
          </div>
          : null}

      </div>

    </div>

  );
}

export function ArticleDetail() {
  const { t } = useTranslation();

  let userLang;

  useEffect(() => {
    userLang = navigator.language.slice(0, 2) || 'en';
    console.log('userLang', userLang)
    i18n.changeLanguage(userLang)
  }, []);

  const { articleId } = useParams();
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
  const [mediaLanguage, setMediaLanguage] = useState(localStorage.getItem('mediaLanguage') || null);
  const [writerData, setWriterData] = useState(JSON.parse(localStorage.getItem("writerData")) || null);
  const [article, setArticle] = useState([]);
  const auth = getAuth();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [orgs, setOrgs] = useState([])
  const [articleTitle, setArticleTitle] = useState("");
  const [articleTopic, setArticleTopic] = useState("");
  const [articleContent, setArticleContent] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [imageCredit, setImageCredit] = useState("");
  const [writtenBy, setWrittenBy] = useState("");
  const [sponsors, setSponsors] = useState("");

  const [short, setShort] = useState(false);
  const shorts = [{ value: false, label: "NO" }, { value: true, label: "YES" }];


  useEffect(() => { // RÉCUPÉRATION DONNÉES ARTICLE
    if (mediaLanguage && userEmail) {

      const articlesRef = doc(db, "news", "media", mediaLanguage, userEmail, "articles", articleId);

      const unsubscribeArticles = onSnapshot(articlesRef, (articleDoc) => {
        if (articleDoc.exists()) {
          setArticle(articleDoc.data());
        }
      });


      // Assurez-vous de vous désabonner de l'écouteur lorsque le composant est démonté
      return () => {

        unsubscribeArticles();
      }
    }
  }, [auth, db, mediaLanguage, userEmail, isEditing]);

  useEffect(() => {//RÉCUPÉRATION ORGA
    const fetchOrgs = async () => {

      const orgsDocRef = doc(db, "fightData", "organisations");

      try {
        const orgsDocSnap = await getDoc(orgsDocRef);

        if (orgsDocSnap.exists()) {
          const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
          setOrgs(listOrgasNames);
        } else {
          console.log("Document 'organisations' non trouvé.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document:", error);
      }
    };

    fetchOrgs();
  }, []);

  useEffect(() => {
    // Quand mediaData change, mettez à jour les valeurs modifiables
    setArticleTitle(article.title);
    setArticleTopic(article.topic);
    setArticleImage(article.image);
    setSponsors(article.sppnsors)
    if (article?.imageCredit) {
      setImageCredit(article.imageCredit)
    }

    if (article?.writtenBy) {
      setWrittenBy(article.writtenBy)
    }

    if (article?.short) {
      setShort(true)
    }

    if (article?.content) {

      // Vérifiez si l'instance EasyMDE (MARKDOWN: STYLISATION DU TEXTE) existe et mettez à jour son contenu
      if (editorRef.current && editorRef.current.easyMDEInstance) {
        editorRef.current.easyMDEInstance.value(article.content);
      }

      setArticleContent(article.content); // Mettre à jour l'état React

    }
  }, [article, isEditing]);

  useEffect(() => {
    if (article.content && editorRef.current && editorRef.current.easyMDEInstance) {
      editorRef.current.easyMDEInstance.value(article.content);
    }
  }, [article.content]);


  const fileInputRef = useRef();// sélectionneur d'image


  //// image 
  const [imageFile, setImageFile] = useState(null);
  const [noFileSelected, setNoFileSelected] = useState(true);

  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const imageRef = useRef();
  const cropperRef = useRef();

  // Gestionnaire pour confirmer le rognage
  const handleCropConfirm = () => {
    cropperRef.current.getCroppedCanvas().toBlob((blob) => {
      setCroppedImageBlob(blob); // Stocker le blob pour la télécharger plus tard
    }, 'image/webp');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);
      setNoFileSelected(false);

      // Lire l'image et l'afficher pour le recadrage
      const reader = new FileReader();
      reader.onload = (e) => {
        imageRef.current.src = e.target.result;
        if (cropperRef.current) {
          cropperRef.current.destroy(); // Détruire l'instance précédente
        }
        cropperRef.current = new Cropper(imageRef.current, {
          aspectRatio: short ? 1080 / 1350 : 1200 / 628 // Ratio d'aspect souhaité
          //... autres options
        });
      };
      reader.readAsDataURL(file);
    }
  };


  ////////////////:

  const textAreaRef = useRef(null);

  const countWords = text => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const isFightAppLoggedIn = userEmail === "media@fightapp.fr" || userEmail === "mediaen@fightapp.fr";

  const handleEdit = async (e) => {
    e.preventDefault();

    if (isFightAppLoggedIn) {
      if (writerData?.name !== writtenBy) {
        alert('Error: Access Denied');
        return;
      }
    }


    if (imageFile && articleImage) {
      // Supprimez l'ancienne image de Firebase Storage
      try {
        // Créez une référence à l'ancienne image
        const oldImageRef = ref(storage, articleImage);
        if (oldImageRef) {
          await deleteObject(oldImageRef);
        }
        console.log('Ancienne image supprimée avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression de l’ancienne image:', error);
      }
    }

    let imageUrl = "";
    const dateTimeSubmission = new Date().toISOString();

    if (imageFile) {
      if (croppedImageBlob) {
        try {
          // Options de compression
          const options = {
            maxSizeMB: 0.10, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
            maxWidthOrHeight: 1920, // Largeur/hauteur maximale (ajustez selon vos besoins)
            useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
            fileType: 'image/webp', // Convertir en WebP
          };

          // Compresser l'image rognée
          const compressedBlob = await imageCompression(croppedImageBlob, options);

          const storagePath = `mediaImages/${userEmail}/${dateTimeSubmission}_${imageFile.name}`;
          const storageRef = ref(storage, storagePath);

          const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
          imageUrl = await getDownloadURL(snapshot.ref);
          setArticleImage(imageUrl);
        } catch (error) {
          console.error("Erreur lors du téléchargement de l'image:", error);
          return; // Quittez la fonction si le téléchargement échoue
        }
      } else {
        setNoFileSelected(true);
        console.log('Veuillez confirmer le rognage de l’image');
        return;
      }
    } else {
      console.log('Aucun fichier sélectionné');
    }

    const writerName = writerData?.name ? writerData?.name : writtenBy ? writtenBy : null;
    try {

      const articleRef = doc(db, "news", "media", mediaLanguage, userEmail, "articles", articleId);

      // Utilisation de updateDoc au lieu de setDoc
      await updateDoc(articleRef, {
        title: articleTitle,
        content: articleContent,
        image: imageUrl ? imageUrl : articleImage,
        topic: articleTopic,
        imageCredit: imageCredit ? imageCredit : null,
        writtenBy: writerName,
        sponsors: sponsors ? sponsors : null,
        short: short ? true : false
      });

    } catch (error) {
      console.error("Error updating document: ", error);
    }

    alert(t("Article edited successfully"));
  };

  ////// SOULIGNAGE ET MISE EN GRAS
  // Display sponsors editing if the account is FightApp, > not allowing other medias to have sponsors for the moment
  // This can be easily hacked and change by people, so if people achieve to displays their own ads, it's probably because of that.
  // or they have been able to acces their document in firestore because the rules allowe it

  const editorRef = useRef(null);

  useEffect(() => { // MARKDOWN
    if (!editorRef.current) return;

    const toolbar = [
      "bold",
      "italic",
      "heading",
      "|",
      "quote",
      ...(isFightAppLoggedIn
        ? [{
          name: "insertImageMarker",
          action: editor => {
            const cursorPosition = editor.codemirror.getCursor();
            editor.codemirror.replaceRange('[[SPONSOR]]', cursorPosition);
          },
          className: "fa fa-image", // Utilisez une icône appropriée
          title: t("Insert your sponsor's image"),
        }]
        : [])
    ];

    const instance = new EasyMDE({
      element: editorRef.current,
      toolbar,
      initialValue: articleContent,
    });


    instance.codemirror.on("change", () => {
      setArticleContent(instance.value());
    });

    // Stocker l'instance dans une référence pour y accéder plus tard
    editorRef.current.easyMDEInstance = instance;

    return () => {
      instance.toTextArea();
    };
  }, [isEditing, isFightAppLoggedIn]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <div style={{
        height: '50px', width: '100%', display: 'flex',
        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', position: 'fixed', // Style clé pour fixer l'élément
        top: '0', // Positionner en haut de la page
        left: '0', // Aligner à gauche
        zIndex: 1000
      }}>
        <button onClick={() => navigate(-1)} style={{
          background: 'none', border: 'none', color: 'white',
          cursor: 'pointer', fontWeight: 'bolder', width: '4%', justifyContent: 'center', display: 'flex'
        }}>
          <FaArrowLeft size="1.3em" />
        </button>
        <div style={{
          width: '80%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis', padding: '0 5px'
        }}>
          <h2 style={{
            color: 'white',
            display: 'inline-block',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0
          }}>{article.title}</h2>
        </div>
      </div>

      <div style={{ height: '50px', width: '100%' }}></div>

      <form onSubmit={handleEdit} style={{
        width: '60%', margin: '0 auto', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', padding: '20px', borderRadius: '10px', backgroundColor: '#f2f2f2',
      }}>

        {/* SHORT */}
        <div>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Short')}?</label>
          <select
            value={short}
            onChange={(e) => setShort(e.target.value === 'true')}
            required
            style={{
              width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
              borderRadius: '5px', outline: 'none', backgroundColor: 'white',
            }}>
            <option value="">{t('YES OR NO')}</option>
            {shorts.map((short, index) => (
              <option key={index} value={short.value}>
                {short.label}
              </option>
            ))}
          </select>

        </div>

        {/* Titre */}
        {!short ?
          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', marginBottom: '10px' }}>{t('Title')}:</label>

            <input
              type="text"
              value={articleTitle}
              onChange={(e) => setArticleTitle(e.target.value)}
              style={{
                width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
              }}
            />

          </div>
          : null}

        {/* Sujet */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Subject')}:</label>

          <select
            value={articleTopic}
            onChange={(e) => setArticleTopic(e.target.value)}
            style={{
              width: '90%', padding: '10px 15px', border: '1px solid #cccccc', borderRadius: '5px', outline: 'none',
            }}
          >
            <option value="">{t('Select a topic')}</option>
            {orgs.map((org, index) => (
              <option key={index} value={org}>{org}</option>
            ))}
          </select>

        </div>

        <label style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}>{t("You can place the sponsor's image in the middle of the text or at the end by clicking on the image icon.")}</label>

        {/* Contenu */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Content')}:</label>

          <div>
            <div style={{ flex: 1 }}>


              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <div style={{ border: '1px solid #cccccc', borderRadius: '5px', padding: '10px' }}>
                    <textarea ref={editorRef} />
                  </div>
                </div>

              </div>

            </div>
            <div style={{ marginTop: '10px', fontSize: '13px', fontWeight: 'bold', color: "grey" }}>
              <label>{t('WORDS')} : {countWords(articleContent)}</label>

            </div>
          </div>

        </div>

        {/* Image */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Image')}:</label>

          <div>
            <label htmlFor="file-upload" style={{ marginBottom: '5px', display: 'block', fontSize: 14, color: 'grey' }}>
              {t('Choose another image')}:
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleImageChange}
              style={{
                width: '90%', padding: '10px 15px', border: '1px solid #cccccc', borderRadius: '5px', outline: 'none',
              }}
            />
          </div>

          {article.image ?
            <div>
              <img
                src={article.image}
                alt={article.title}
                style={{
                  width: '100%', borderRadius: '5px', marginTop: '10px'
                }} />
            </div>
            : null}

          {!noFileSelected ?
            <button style={{ width: '50%', marginBottom: '10px' }} type="button" onClick={handleCropConfirm}>{t('Confirm cropping')}</button>
            : null}

          {imageRef && !noFileSelected ?
            <div style={{ width: '500px', height: '500px', overflow: 'hidden' }}>
              <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Preview" />
            </div>
            : null}
        </div>

        {/* Crédit Image */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Image credit (if necessary)')}:</label>

          <input
            type="text"
            value={imageCredit}
            onChange={(e) => setImageCredit(e.target.value)}
            style={{
              width: '90%', padding: '10px 15px', border: '1px solid #cccccc', borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
            }}
          />

        </div>

        {/* Crédit écriture */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>{t('Written by (if necessary)')}:</label>

          <input
            type="text"
            value={writtenBy}
            onChange={(e) => setWrittenBy(e.target.value)}
            style={{
              width: '90%', padding: '10px 15px', border: '1px solid #cccccc', borderRadius: '5px', outline: 'none', fontFamily: "'Arial', sans-serif",
            }}
          />

        </div>

        {/* Boutons */}

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <button type="submit" style={{
            padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
            color: 'white', borderRadius: '5px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s ease-in-out',
          }}>
            {t('Save')}
          </button>

          <button onClick={() => setIsEditing(false)} style={{
            padding: '10px 20px', border: 'none', backgroundColor: 'grey',
            color: 'white', borderRadius: '5px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s ease-in-out',
          }}>
            {t('Cancel')}
          </button>
        </div>

      </form>

    </div>
  );
}

export async function isUsernameTaken(username, mediaLanguage) {
  const newsMediaRef = collection(db, "news", "media", mediaLanguage);
  const q = query(newsMediaRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
}

// Vous devrez peut-être configurer le chemin des workers PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export function PdfGuidelines() {
  const { t } = useTranslation();
  const location = useLocation();
  const userLang = location.state?.userLang || 'en';
  console.log('userLang2', userLang)
  const navigate = useNavigate();
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <div style={{
        height: '50px', width: '100%', display: 'flex',
        justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#ff3030', position: 'fixed', // Style clé pour fixer l'élément
        top: '0', // Positionner en haut de la page
        left: '0', // Aligner à gauche
        zIndex: 1000
      }}>
        <button onClick={() => navigate(-1)} style={{
          background: 'none', border: 'none', color: 'white',
          cursor: 'pointer', fontWeight: 'bolder', width: '4%', justifyContent: 'center', display: 'flex'
        }}>
          <FaArrowLeft size="1.3em" />
        </button>

      </div>

      <div style={{ height: '50px', width: '100%' }}></div>

      <Document
        file={userLang === 'fr' ? "/guidelines-fr.pdf" : "/guidelines-en.pdf"}
        onLoadSuccess={({ numPages }) => console.log(`Le document a ${numPages} pages.`)}
      >
        <Page pageNumber={1} renderTextLayer={false} scale={1.5} />
        <Page pageNumber={2} renderTextLayer={false} scale={1.5} />
        <Page pageNumber={3} renderTextLayer={false} scale={1.5} />
        <Page pageNumber={4} renderTextLayer={false} scale={1.5} />

      </Document>
    </div>
  );
};